import React from "react";
import "../../css/admin/RecommendationEngine.css";

const RecommendationEngine = () => {
  // Sample data (replace with API data in production)
  const recommendations = [
    {
      userId: "12345678",
      threatType: "Phishing",
      actionableInsight: "Reset Password",
      timestamp: "2024-11-10 12:45:00",
    },
    {
      userId: "87654321",
      threatType: "SQL Injection",
      actionableInsight: "Block IP",
      timestamp: "2024-11-11 15:30:00",
    },
  ];

  return (
    <div className="recommendation-engine">
      <h2>Recommendation Engine</h2>
      <table className="recommendation-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Threat Type</th>
            <th>Actionable Insight</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {recommendations.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.threatType}</td>
              <td>{item.actionableInsight}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecommendationEngine;