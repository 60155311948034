import React, { useState } from "react";
import "../../css/admin/TablePage.css";
import GeoTableRow from "./GeoTableRow";

const GeoTablePage = () => {
  // Sample geolocation data
  const [geoRecords, setGeoRecords] = useState([
    { id: 1, name: "John Doe", location: "New York, USA" },
    { id: 2, name: "Jane Smith", location: "London, UK" },
    { id: 3, name: "Alice Johnson", location: "Sydney, Australia" },
  ]);

  const handleAction = (id, action) => {
    console.log(`Action: ${action} performed on user ID: ${id}`);
    // Implement API or state updates here
  };

  return (
    <div className="table-page">
      <h1>Geolocation Records</h1>
      <table className="user-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Geo Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {geoRecords.map((record) => (
            <GeoTableRow key={record.id} record={record} onAction={handleAction} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GeoTablePage;