// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    display: flex;
  }
  
  .home-content {
    margin-left: 20px;
    padding: 20px;
    flex: 1 1;
  }
  
  .charts {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .chart {
    flex: 1 1;
    min-width: 300px;
    max-width: 600px;
  }
  
  @media (max-width: 768px) {
    .home-content {
      margin-left: 0;
    }
  
    .chart {
      min-width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,SAAO;EACT;;EAEA;IACE,aAAa;IACb,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE;MACE,cAAc;IAChB;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":[".home {\n    display: flex;\n  }\n  \n  .home-content {\n    margin-left: 20px;\n    padding: 20px;\n    flex: 1;\n  }\n  \n  .charts {\n    display: flex;\n    gap: 20px;\n    flex-wrap: wrap;\n  }\n  \n  .chart {\n    flex: 1;\n    min-width: 300px;\n    max-width: 600px;\n  }\n  \n  @media (max-width: 768px) {\n    .home-content {\n      margin-left: 0;\n    }\n  \n    .chart {\n      min-width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
