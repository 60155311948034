import React from "react";
import "../../css/admin/TablePage.css";

const TableRow = ({ user, onAction }) => {
  return (
    <tr>
      <td>{user.id}</td>
      <td>{user.name}</td>
      <td>{user.deviceType}</td>
      <td>
        <button
          className="action-btn suspend"
          onClick={() => onAction(user.id, "Suspend")}
        >
          Suspend
        </button>
        <button
          className="action-btn hold"
          onClick={() => onAction(user.id, "Hold Logins")}
        >
          Hold Logins
        </button>
        <button
          className="action-btn disable"
          onClick={() => onAction(user.id, "Disable")}
        >
          Disable
        </button>
      </td>
    </tr>
  );
};

export default TableRow;