import React from "react";
import "../../css/admin/PrivacyCompliance.css";

const PrivacyCompliance = () => {
  // Sample data (replace with API data later)
  const complianceData = [
    {
      userId: "12345678",
      complianceFramework: "GDPR",
      timestamp: "2024-11-01 10:30:00",
    },
    {
      userId: "87654321",
      complianceFramework: "CCPA",
      timestamp: "2024-11-02 14:45:00",
    },
  ];

  return (
    <div className="privacy-compliance">
      <h2>Privacy Compliance Monitoring</h2>
      <table className="compliance-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Compliance Framework</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {complianceData.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.complianceFramework}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrivacyCompliance;