import React from "react";
import "../../css/admin/IncidentManagement.css";

const IncidentManagement = () => {
  // Sample data (replace with API data in production)
  const incidents = [
    {
      userId: "12345678",
      threatType: "DDoS",
      loginStatus: "Failed",
      accessAnomalies: 3,
      adminNotified: true,
      timestamp: "2024-11-12 10:15:00",
    },
    {
      userId: "87654321",
      threatType: "Brute Force",
      loginStatus: "Successful",
      accessAnomalies: 1,
      adminNotified: false,
      timestamp: "2024-11-13 18:45:00",
    },
  ];

  return (
    <div className="incident-management">
      <h2>Incident Management</h2>
      <table className="incident-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Threat Type</th>
            <th>Login Status</th>
            <th>Access Anomalies</th>
            <th>Admin Notified</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {incidents.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.threatType}</td>
              <td>{item.loginStatus}</td>
              <td>{item.accessAnomalies}</td>
              <td>{item.adminNotified ? "Yes" : "No"}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncidentManagement;