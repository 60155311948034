import React from "react";
import Sidebar from "./components/admin/Sidebar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/admin/Home";
import TablePage from "./components/admin/TablePage";
import GeoTablePage from "./components/admin/GeoTablePage";
import AccessLogsPage from "./components/admin/AccessLogsPage";
import RiskScoreDashboard from "./components/admin/RiskScoreDashboard";
import UserManagement from "./components/admin/UserManagement";
import AnalyticsDashboard from "./components/admin/AnalyticsDashboard";
import EncryptionMonitoring from "./components/admin/EncryptionMonitoring";
import PrivacyCompliance from "./components/admin/PrivacyCompliance";
import RecommendationEngine from "./components/admin/RecommendationEngine";
import IncidentManagement from "./components/admin/IncidentManagement";
import BehaviorMonitoring from "./components/admin/BehaviorMonitoring";
import AnomalyDetection from "./components/admin/AnomalyDetection";
import ThreatMitigation from "./components/admin/ThreatMitigation";
import SystemUpdates from "./components/admin/SystemUpdates";
import UserBehaviorForm from "./components/admin/UserBehaviorForm";
import AnomalyDetectionUser from "./components/admin/AnomalyDetectionUser";
import "./css/App.css";

function App() {
  return (
    <Router>
      <div className="admin-layout">
        <Sidebar />
        <div className="admin-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/table" element={<TablePage />} />
            <Route path="/geolocations" element={<GeoTablePage />} />
            <Route path="/access-logs" element={<AccessLogsPage />} />
            <Route path="/risk-scores" element={<RiskScoreDashboard />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/analytics" element={<AnalyticsDashboard />} />
            <Route path="/encryption-monitoring" element={<EncryptionMonitoring />} />
            <Route path="/privacy-compliance" element={<PrivacyCompliance />} />
            <Route path="/recommendation-engine" element={<RecommendationEngine />} />
            <Route path="/incident-management" element={<IncidentManagement />} />
            <Route path="/behavior-monitoring" element={<BehaviorMonitoring />} />
            <Route path="/anomaly-detection" element={<AnomalyDetection />} />
            <Route path="/threat-mitigation" element={<ThreatMitigation />} />
            <Route path="/system-updates" element={<SystemUpdates />} />
            <Route path="/user-behavior" element={<UserBehaviorForm userId="sampleUserId123" />} />
            <Route path="/anomaly-detection-user" element={<AnomalyDetectionUser />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;