// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management-page {
    padding: 20px;
  }
  
  .management-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .management-table th,
  .management-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }

  .action-btn {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .action-btn.accept {
    background-color: #2ecc71;
    color: white;
  }
  
  .action-btn.remove {
    background-color: #e74c3c;
    color: white;
  }
  
  .action-btn:hover {
    opacity: 0.8;
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/UserManagement.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,YAAY;EACd","sourcesContent":[".management-page {\n    padding: 20px;\n  }\n  \n  .management-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .management-table th,\n  .management-table td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n\n  .action-btn {\n    padding: 5px 10px;\n    margin: 0 5px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n  }\n  \n  .action-btn.accept {\n    background-color: #2ecc71;\n    color: white;\n  }\n  \n  .action-btn.remove {\n    background-color: #e74c3c;\n    color: white;\n  }\n  \n  .action-btn:hover {\n    opacity: 0.8;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
