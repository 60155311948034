import React from "react";
import "../../css/admin/EncryptionMonitoring.css";

const EncryptionMonitoring = () => {
  // Sample data (replace with API data later)
  const encryptionData = [
    {
      userId: "12345678",
      encryptionProtocol: "TLS 1.3",
      anonymizationMethod: "Hashing",
      dataEncrypted: true,
      encryptionSuccessful: true,
      timestamp: "2024-11-01 10:30:00",
    },
    {
      userId: "87654321",
      encryptionProtocol: "AES-256",
      anonymizationMethod: "Data Masking",
      dataEncrypted: false,
      encryptionSuccessful: null,
      timestamp: "2024-11-02 14:45:00",
    },
  ];

  return (
    <div className="encryption-monitoring">
      <h2>Encryption Monitoring</h2>
      <table className="encryption-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Encryption Protocol</th>
            <th>Anonymization Method</th>
            <th>Data Encrypted</th>
            <th>Encryption Successful</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {encryptionData.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.encryptionProtocol || "N/A"}</td>
              <td>{item.anonymizationMethod}</td>
              <td>{item.dataEncrypted ? "Yes" : "No"}</td>
              <td>
                {item.dataEncrypted
                  ? item.encryptionSuccessful
                    ? "Yes"
                    : "No"
                  : "N/A"}
              </td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EncryptionMonitoring;