// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-left: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  }
  
  .user-table th,
  .user-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .user-table th {
    background-color: #2c3e50;
    color: white;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .action-btn {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .action-btn.suspend {
    background-color: #e74c3c;
    color: white;
  }
  
  .action-btn.hold {
    background-color: #f39c12;
    color: white;
  }
  
  .action-btn.disable {
    background-color: #7f8c8d;
    color: white;
  }
  
  .action-btn:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    .user-table {
      font-size: 12px;
    }
  
    .action-btn {
      font-size: 12px;
      padding: 4px 8px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/TablePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;MACf,gBAAgB;IAClB;EACF","sourcesContent":[".table-page {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    margin-left: 20px;\n  }\n  \n  h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .user-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 0 auto;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n  }\n  \n  .user-table th,\n  .user-table td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n  \n  .user-table th {\n    background-color: #2c3e50;\n    color: white;\n  }\n  \n  .user-table tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  .action-btn {\n    padding: 5px 10px;\n    margin: 0 5px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n  }\n  \n  .action-btn.suspend {\n    background-color: #e74c3c;\n    color: white;\n  }\n  \n  .action-btn.hold {\n    background-color: #f39c12;\n    color: white;\n  }\n  \n  .action-btn.disable {\n    background-color: #7f8c8d;\n    color: white;\n  }\n  \n  .action-btn:hover {\n    opacity: 0.8;\n  }\n\n  @media (max-width: 768px) {\n    .user-table {\n      font-size: 12px;\n    }\n  \n    .action-btn {\n      font-size: 12px;\n      padding: 4px 8px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
