// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.admin-layout {
  display: flex; /* Enables side-by-side layout */
}

.admin-content {
  margin-left: 250px; /* Leaves space for the fixed sidebar */
  padding: 20px;
  flex: 1 1; /* Ensures the content takes the remaining space */
  background-color: #f9f9f9; /* Light background for contrast */
  min-height: 100vh; /* Ensures the content area covers full height */
  overflow-x: hidden; /* Prevents horizontal scroll */
}

h1 {
  color: #333; /* Darker text for readability */
}

p {
  color: #666;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,aAAa,EAAE,gCAAgC;AACjD;;AAEA;EACE,kBAAkB,EAAE,uCAAuC;EAC3D,aAAa;EACb,SAAO,EAAE,kDAAkD;EAC3D,yBAAyB,EAAE,kCAAkC;EAC7D,iBAAiB,EAAE,gDAAgD;EACnE,kBAAkB,EAAE,+BAA+B;AACrD;;AAEA;EACE,WAAW,EAAE,gCAAgC;AAC/C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.admin-layout {\n  display: flex; /* Enables side-by-side layout */\n}\n\n.admin-content {\n  margin-left: 250px; /* Leaves space for the fixed sidebar */\n  padding: 20px;\n  flex: 1; /* Ensures the content takes the remaining space */\n  background-color: #f9f9f9; /* Light background for contrast */\n  min-height: 100vh; /* Ensures the content area covers full height */\n  overflow-x: hidden; /* Prevents horizontal scroll */\n}\n\nh1 {\n  color: #333; /* Darker text for readability */\n}\n\np {\n  color: #666;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
