import React, { useState, useEffect } from "react";
import "../../css/admin/AccessLogsPage.css";

const AccessLogsPage = () => {
  const [accessLogs, setAccessLogs] = useState([
    {
      id: 1,
      name: "John Doe",
      accessCount: 5,
      lastAccessTime: "2024-11-20 14:35",
      sessionDuration: "15m",
    },
    {
      id: 2,
      name: "Jane Smith",
      accessCount: 3,
      lastAccessTime: "2024-11-20 15:50",
      sessionDuration: "8m",
    },
    {
      id: 3,
      name: "Alice Johnson",
      accessCount: 7,
      lastAccessTime: "2024-11-20 13:25",
      sessionDuration: "25m",
    },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Simulating new session duration updates...");
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="table-page">
      <h1>Access Logs</h1>
      <table className="access-logs-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Access Count</th>
            <th>Last Access Time</th>
            <th>Session Duration</th>
          </tr>
        </thead>
        <tbody>
          {accessLogs.map((log) => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>{log.name}</td>
              <td>{log.accessCount}</td>
              <td>{log.lastAccessTime}</td>
              <td>{log.sessionDuration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccessLogsPage;