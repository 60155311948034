import React, { useState } from "react";
import "../../css/admin/TablePage.css";
import TableRow from "./TableRow";

const TablePage = () => {
  const [users, setUsers] = useState([
    { id: 1, name: "John Doe", deviceType: "Mobile" },
    { id: 2, name: "Jane Smith", deviceType: "Desktop" },
    { id: 3, name: "Alice Johnson", deviceType: "Tablet" },
  ]);

  const handleAction = (id, action) => {
    console.log(`Action: ${action} performed on user ID: ${id}`);
  };

  return (
    <div className="table-page">
      <h1>User Device Information</h1>
      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Device Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <TableRow key={user.id} user={user} onAction={handleAction} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePage;