import React, { useState } from "react";
import "../../css/admin/anomalyDetectionUser.css";

const AnomalyDetectionUser = () => {
  const [userId, setUserId] = useState("");
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { userId };
    try {
      const response = await fetch("http://127.0.0.1:5004/predict", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the server.");
      }

      const result = await response.json();
      setResults(result);
      setError(null);
    } catch (err) {
      setError(err.message);
      setResults(null);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h2>Anomaly Detection</h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <label htmlFor="userId" style={{ marginRight: "10px" }}>
          User ID:
        </label>
        <input
          type="text"
          id="userId"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
          style={{ marginRight: "10px" }}
        />
        <button type="submit">Submit</button>
      </form>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {results && (
        <div>
          <h3>Results:</h3>
          <ul>
            <li><strong>User ID:</strong> {results.userId}</li>
            <li><strong>Anomaly Type:</strong> {results.anomalyType}</li>
            <li>
              <strong>Historical Pattern Similarity:</strong> {results.historicalPatternSimilarity}
            </li>
            <li>
              <strong>Real-Time Behavior Score:</strong> {results.realTimeBehaviorScore}
            </li>
            <li><strong>Detected Time:</strong> {results.detectedTime}</li>
            <li><strong>K-Means Cluster:</strong> {results.KMeansCluster}</li>
            <li><strong>DBSCAN Cluster:</strong> {results.DBSCANCluster}</li>
            <li><strong>Isolation Forest Anomaly:</strong> {results.IsolationForestAnomaly}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AnomalyDetectionUser;