import React from "react";
import "../../css/admin/BehaviorMonitoring.css";

const BehaviorMonitoring = () => {
  // Sample data (replace with API data later)
  const behaviorData = [
    {
      userId: "12345678",
      loginTime: "2024-11-20 10:30:00",
      loginFrequency: 15,
      deviceUsed: "Mobile",
      scrollBehavior: "Smooth",
      typicalActions: "Click, Scroll",
      loginAttempts: 3,
      webcamVerification: "Verified",
    },
    {
      userId: "87654321",
      loginTime: "2024-11-21 14:15:00",
      loginFrequency: 7,
      deviceUsed: "Desktop",
      scrollBehavior: "Jerky",
      typicalActions: "Type, Scroll",
      loginAttempts: 5,
      webcamVerification: "Not Verified",
    },
  ];

  return (
    <div className="behavior-monitoring">
      <h2>Behavior Monitoring</h2>
      <table className="behavior-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Login Time</th>
            <th>Login Frequency</th>
            <th>Device Used</th>
            <th>Scroll Behavior</th>
            <th>Typical Actions</th>
            <th>Login Attempts</th>
            <th>Webcam Verification</th>
          </tr>
        </thead>
        <tbody>
          {behaviorData.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.loginTime}</td>
              <td>{item.loginFrequency}</td>
              <td>{item.deviceUsed}</td>
              <td>{item.scrollBehavior}</td>
              <td>{item.typicalActions}</td>
              <td>{item.loginAttempts}</td>
              <td>{item.webcamVerification}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BehaviorMonitoring;