import React, { useState } from "react";
import "../../css/admin/Sidebar.css";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`sidebar ${isOpen ? "open" : "hidden"}`}>
        <h2 className="sidebar-title">Admin Dashboard</h2>
        <ul className="sidebar-menu">
          <li className="sidebar-header">Main</li>
          <ul>
          <li className="sidebar-item">
            <a href="/">Home</a>
          </li>
          </ul>
          
          <li className="sidebar-header">User Management</li>
          <ul>
          <li className="sidebar-item">
            <a href="/table">User Devices</a>
          </li>
          <li className="sidebar-item">
            <a href="/geolocations">Geolocations</a>
          </li>
          <li className="sidebar-item">
            <a href="/access-logs">Access Logs</a>
          </li>
          <li className="sidebar-item">
            <a href="/user-management">Manage Users</a>
          </li>
          </ul>

          <li>
          <li className="sidebar-header">Monitoring</li>
          <ul>
            <li className="sidebar-item"><a href="/behavior-monitoring">Behavior Monitoring</a></li>
            <li className="sidebar-item"><a href="/anomaly-detection">Anomaly Detection</a></li>
          </ul>
        </li>
        <li>
          <li className="sidebar-header">Threat Management</li>
          <ul>
            <li className="sidebar-item"><a href="/threat-mitigation">Threat Mitigation</a></li>
            <li className="sidebar-item"><a href="/system-updates">System Updates</a></li>
          </ul>
        </li>
          
          <li className="sidebar-header">Risk & Analytics</li>
          <ul>
          <li className="sidebar-item">
            <a href="/risk-scores">Risk Scores</a>
          </li>
          <li className="sidebar-item"><a href="/encryption-monitoring">Encryption Monitoring</a></li>
        <li className="sidebar-item"><a href="/privacy-compliance">Privacy Compliance</a></li>
          <li className="sidebar-item">
            <a href="/analytics">Analytics</a>
          </li>
          </ul>
                    <li className="sidebar-header">Recommendation And Incidents</li>
                    <ul>
                    <li className="sidebar-item"><a href="/recommendation-engine">Recommendation Engine</a></li>
                    <li className="sidebar-item"><a href="/incident-management">Incident Management</a></li>
                    </ul>
          <li className="sidebar-header">Other</li>
          <ul>
          <li className="sidebar-item">
            <a href="/suggestions">Suggestions</a>
          </li>
          </ul>
          <li className="sidebar-header">Front End</li>
          <ul>
          <li className="sidebar-item">
            <a href="/suggestions">User Behavior</a>
          </li>
          <li className="sidebar-item">
            <a href="/anomaly-detection-user">Anomaly Dtection User</a>
          </li>
          </ul>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;