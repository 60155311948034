import React from "react";
import "../../css/admin/ThreatMitigation.css";

const ThreatMitigation = () => {
  // Sample data (replace with API data later)
  const threatData = [
    {
      userId: "12345678",
      mitigationAction: "Access Revoked",
      adminNotified: "Yes",
      timestamp: "2024-11-20 12:00:00",
    },
    {
      userId: "87654321",
      mitigationAction: "Reauthentication Required",
      adminNotified: "No",
      timestamp: "2024-11-21 16:00:00",
    },
  ];

  return (
    <div className="threat-mitigation">
      <h2>Threat Mitigation</h2>
      <table className="threat-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Mitigation Action</th>
            <th>Admin Notified</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {threatData.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.mitigationAction}</td>
              <td>{item.adminNotified}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ThreatMitigation;