// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.encryption-monitoring {
    padding: 20px;
  }
  
  .encryption-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .encryption-table th, .encryption-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .encryption-table th {
    background-color: #2c3e50;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/EncryptionMonitoring.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".encryption-monitoring {\n    padding: 20px;\n  }\n  \n  .encryption-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .encryption-table th, .encryption-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .encryption-table th {\n    background-color: #2c3e50;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
