// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .access-logs-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  }
  
  .access-logs-table th,
  .access-logs-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .access-logs-table th {
    background-color: #2c3e50;
    color: white;
  }
  
  .access-logs-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/AccessLogsPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".table-page {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .access-logs-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 0 auto;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n  }\n  \n  .access-logs-table th,\n  .access-logs-table td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n  \n  .access-logs-table th {\n    background-color: #2c3e50;\n    color: white;\n  }\n  \n  .access-logs-table tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
