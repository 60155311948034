import React, { useState, useEffect } from 'react';
import '../../css/admin/UserBehaviorForm.css';

const UserBehaviorForm = ({ userId }) => {
  const [behaviorData, setBehaviorData] = useState({
    typingSpeed: 0,
    dwellTime: 0,
    errorRate: 0,
    mouseMovements: 0,
    scrollBehavior: 0,
  });

  useEffect(() => {
    let typingStartTime = Date.now();
    let totalTypedChars = 0;

    const handleTyping = () => {
      totalTypedChars++;
      const elapsedTime = (Date.now() - typingStartTime) / 1000 / 60;
      setBehaviorData((prev) => ({
        ...prev,
        typingSpeed: totalTypedChars / elapsedTime,
      }));
    };

    document.addEventListener('keydown', handleTyping);
    return () => document.removeEventListener('keydown', handleTyping);
  }, []);

  useEffect(() => {
    const dwellStartTime = Date.now();
    const interval = setInterval(() => {
      const dwellElapsedTime = (Date.now() - dwellStartTime) / 1000;
      setBehaviorData((prev) => ({
        ...prev,
        dwellTime: dwellElapsedTime,
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let errorCount = 0;
    const handleError = (e) => {
      if (e.key === 'Backspace') errorCount++;
      setBehaviorData((prev) => ({
        ...prev,
        errorRate: errorCount,
      }));
    };

    document.addEventListener('keydown', handleError);
    return () => document.removeEventListener('keydown', handleError);
  }, []);

  useEffect(() => {
    let moveCount = 0;
    const handleMouseMove = () => {
      moveCount++;
      setBehaviorData((prev) => ({
        ...prev,
        mouseMovements: moveCount,
      }));
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setBehaviorData((prev) => ({
        ...prev,
        scrollBehavior: window.scrollY,
      }));
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const data = { userId, ...behaviorData };
      fetch('https://itrust360.cloud/api/behavior', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            console.log('Data saved successfully');
          } else {
            console.error('Error saving data');
          }
        })
        .catch((error) => console.error('Error:', error));
    }, 3600000);

    return () => clearInterval(interval);
  }, [behaviorData, userId]);

  return (
    <div className="behavior-form">
      <h3 className="behavior-header">User Behavior Tracker</h3>
      <div className="behavior-data">
        <p className="behavior-item">
          Typing Speed: <span>{behaviorData.typingSpeed.toFixed(2)} words per minute</span>
        </p>
        <p className="behavior-item">
          Dwell Time: <span>{behaviorData.dwellTime.toFixed(2)} seconds</span>
        </p>
        <p className="behavior-item">
          Error Rate: <span>{behaviorData.errorRate}</span>
        </p>
        <p className="behavior-item">
          Mouse Movements: <span>{behaviorData.mouseMovements}</span>
        </p>
        <p className="behavior-item">
          Scroll Behavior: <span>{behaviorData.scrollBehavior} pixels</span>
        </p>
      </div>
    </div>
  );
};

export default UserBehaviorForm;