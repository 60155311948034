import React from "react";
import Sidebar from "./Sidebar";
import LineChart from "./charts/LineChart";
import BarChart from "./charts/BarChart";
import ErrorBoundary from "../ErrorBoundary";
import "../../css/admin/Home.css";

const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="home-content">
        <h1>Welcome to the Admin Dashboard</h1>
        <div className="charts">
          <ErrorBoundary>
            <div className="chart">
              <LineChart />
            </div>
          </ErrorBoundary>
          <ErrorBoundary>
            <div className="chart">
              <BarChart />
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Home;