import React, { useState } from "react";
import "../../css/admin/RiskScoreDashboard.css";

const RiskScoreDashboard = () => {
  const [users, setUsers] = useState([
    { id: 1, name: "John Doe", riskScore: 85, riskLevel: "High" },
    { id: 2, name: "Jane Smith", riskScore: 45, riskLevel: "Medium" },
    { id: 3, name: "Alice Johnson", riskScore: 15, riskLevel: "Low" },
  ]);

  const handleAction = (id, action) => {
    console.log(`Action: ${action} performed on User ID: ${id}`);
  };

  return (
    <div className="dashboard-page">
      <h1>Risk Score Dashboard</h1>
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Risk Score</th>
            <th>Risk Level</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.riskScore}</td>
              <td className={`risk-level ${user.riskLevel.toLowerCase()}`}>
                {user.riskLevel}
              </td>
              <td>
                <button
                  className="action-btn update"
                  onClick={() => handleAction(user.id, "Update Risk")}
                >
                  Update Risk
                </button>
                <button
                  className="action-btn disable"
                  onClick={() => handleAction(user.id, "Disable User")}
                >
                  Disable User
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RiskScoreDashboard;