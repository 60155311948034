import React, { useEffect, useState } from "react";
import "../../css/admin/AnomalyDetection.css";

const AnomalyDetection = () => {
  const [anomalyData, setAnomalyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnomalies = async () => {
      try {
        const response = await fetch("https://itrust360.cloud/api/anomalies");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setAnomalyData(data);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching anomalies:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAnomalies();
  }, []);

  const handlePredict = async () => {
    try {
      const response = await fetch("https://itrust360.cloud/api/predict", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: "test_user" }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const prediction = await response.json();
      console.log("Prediction result:", prediction);
      alert(`Prediction: ${JSON.stringify(prediction)}`);
    } catch (err) {
      console.error("Error in prediction:", err.message);
    }
  };

  if (loading) return <div>Loading anomalies...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="anomaly-detection">
      <h2>Anomaly Detection</h2>
      <table className="anomaly-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Anomaly Type</th>
            <th>Historical Pattern Similarity</th>
            <th>Real-Time Behavior Score</th>
          </tr>
        </thead>
        <tbody>
          {anomalyData.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.anomalyType}</td>
              <td>{item.historicalPatternSimilarity}</td>
              <td>{item.realTimeBehaviorScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AnomalyDetection;