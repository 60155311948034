import React, { useState } from "react";
import "../../css/admin/UserManagement.css";

const UserManagement = () => {
  const [users, setUsers] = useState([
    { id: 1, name: "Alice White", riskScore: 90, status: "Pending" },
    { id: 2, name: "Bob Johnson", riskScore: 70, status: "Pending" },
  ]);

  const handleDecision = (id, decision) => {
    console.log(`Decision: ${decision} for User ID: ${id}`);
  };

  return (
    <div className="management-page">
      <h1>User Management</h1>
      <table className="management-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Risk Score</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.riskScore}</td>
              <td>{user.status}</td>
              <td>
                <button
                  className="action-btn accept"
                  onClick={() => handleDecision(user.id, "Accept")}
                >
                  Accept
                </button>
                <button
                  className="action-btn remove"
                  onClick={() => handleDecision(user.id, "Remove")}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;