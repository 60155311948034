// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analytics-page {
    padding: 20px;
  }
  
  .charts {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .chart {
    flex: 1 1;
    min-width: 300px;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/AnalyticsDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,aAAa;IACb,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;EAC1C","sourcesContent":[".analytics-page {\n    padding: 20px;\n  }\n  \n  .charts {\n    display: flex;\n    gap: 20px;\n    flex-wrap: wrap;\n  }\n  \n  .chart {\n    flex: 1;\n    min-width: 300px;\n    max-width: 600px;\n    padding: 20px;\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
