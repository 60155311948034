// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.threat-mitigation {
    padding: 20px;
  }
  
  .threat-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .threat-table th,
  .threat-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .threat-table th {
    background-color: #16a085;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/ThreatMitigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".threat-mitigation {\n    padding: 20px;\n  }\n  \n  .threat-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .threat-table th,\n  .threat-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .threat-table th {\n    background-color: #16a085;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
