import React from "react";
import "../../css/admin/SystemUpdates.css";

const SystemUpdates = () => {
  const updatesData = [
    {
      userId: "12345678",
      updateType: "Behavioral Baseline Updated",
      status: "Success",
      affectedFeatures: "Login Time, Device Consistency",
      timestamp: "2024-11-20 13:30:00",
    },
    {
      userId: "87654321",
      updateType: "Threat Vector Added",
      status: "Failed",
      affectedFeatures: "Geolocation",
      timestamp: "2024-11-21 17:30:00",
    },
  ];

  return (
    <div className="system-updates">
      <h2>Dynamic System Updates</h2>
      <table className="updates-table">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Update Type</th>
            <th>Status</th>
            <th>Affected Features</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {updatesData.map((item, index) => (
            <tr key={index}>
              <td>{item.userId}</td>
              <td>{item.updateType}</td>
              <td>{item.status}</td>
              <td>{item.affectedFeatures}</td>
              <td>{item.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SystemUpdates;