import React from "react";
import "../../css/admin/AnalyticsDashboard.css";

const AnalyticsDashboard = () => {
  return (
    <div className="analytics-page">
      <h1>Analytics Dashboard</h1>
      <div className="charts">
        <div className="chart">
          <h3>Risk Score Distribution</h3>
          {/*chart component*/}
          <p>Chart Placeholder</p>
        </div>
        <div className="chart">
          <h3>Login Trends</h3>
          {/* chart component */}
          <p>Chart Placeholder</p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;