// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page {
    padding: 20px;
  }
  
  .dashboard-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dashboard-table th,
  .dashboard-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .risk-level.low {
    color: green;
  }
  
  .risk-level.medium {
    color: orange;
  }
  
  .risk-level.high {
    color: red;
  }
  
  .action-btn {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .action-btn.update {
    background-color: #3498db;
    color: white;
  }
  
  .action-btn.disable {
    background-color: #e74c3c;
    color: white;
  }
  
  .action-btn:hover {
    opacity: 0.8;
  }`, "",{"version":3,"sources":["webpack://./src/css/admin/anomalyDetectionUser.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,YAAY;EACd","sourcesContent":[".dashboard-page {\n    padding: 20px;\n  }\n  \n  .dashboard-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .dashboard-table th,\n  .dashboard-table td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n  \n  .risk-level.low {\n    color: green;\n  }\n  \n  .risk-level.medium {\n    color: orange;\n  }\n  \n  .risk-level.high {\n    color: red;\n  }\n  \n  .action-btn {\n    padding: 5px 10px;\n    margin: 0 5px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 14px;\n  }\n  \n  .action-btn.update {\n    background-color: #3498db;\n    color: white;\n  }\n  \n  .action-btn.disable {\n    background-color: #e74c3c;\n    color: white;\n  }\n  \n  .action-btn:hover {\n    opacity: 0.8;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
